import React from 'react'
import { Link } from 'gatsby'

import awards from "../../static/img/awards.png"
import vision from "../../static/img/vision.png"
import mission from "../../static/img/mision.png"
import values from "../../static/img/values.png"

import Header from '../components/header'
import Footer from '../components/footer'
import Head from '../components/head'
import SEO from '../components/seo'
import '../styles/index.scss'

const IndexPage = () => {
    return (
        <div>
            <SEO />
            <Head title="Home" />
            <Header />
            <div className="landingDIV" />
            <div className="container" id="content">
                <div className="contentDIV">
                    <h1>Our Company</h1>
                    <hr />
                    <div>Central Summit is a Malaysian-owned company with over 20 years of extensive hands-on experience in fabricating stainless steel water tank, and has earned a great reputation in the stainless steel water tank market.</div>
                    <div className="content-company-intro">
                        <div className="content-company-attr">
                            <div><img className="content-company-attr-logo" src={vision} alt="Vision" /></div>
                            <br />
                            <div><b>Vision</b></div>
                            <br />
                            <div>To earn the worldwide acknowledgement in the market and to be afforable for all </div>
                        </div>
                        <div className="content-company-attr">
                            <div><img className="content-company-attr-logo" src={mission} alt="Mission" /></div>
                            <br />
                            <div><b>Mission</b></div>
                            <br />
                            <div>To provide a better and healthier water storage in conjunction to promotes good health </div>
                        </div>
                        <div className="content-company-attr">
                            <div><img className="content-company-attr-logo" src={values} alt="Values" /></div>
                            <br />
                            <div><b>Values</b></div>
                            <br />
                            <div>Committed to achieve customer satisfaction by complying with requirements through product quality </div>
                        </div>
                    </div>
                </div>
                <div className="contentDIV">
                    <h1>Products</h1>
                    <hr />
                    <div>We use our in-house machinery and verified top quality steel materials to craft fabricate the premium quality of Golden stainless steel water tank.<br />
                    Find out more about our products <Link to="/products">here.</Link></div>
                </div>
                <div className="contentDIV">
                    <h1>Quality Policy</h1>
                    <hr />
                    <div>Central Summit Sdn. Bhd. is a manufacturer for stainless steel and mild steel products. We are fully committed to achieve customer satisfaction by complying with requirements through product quality, on time delivery and continually improving the effectiveness of quality management system.</div>
                </div>
                <div className="contentDIV">
                    <h1>Awards and Certificates</h1>
                    <hr />
                    <img src={awards} alt="Awards" width="100%" />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default IndexPage